<template>
  <v-card class="mt-2 ml-2 mr-2">
    <FPeriodeTable/>
  </v-card>
</template>

<script>
import FPeriodeTable from "../../components/akademis/periode/FPeriodeTable";

export default {
  components: { FPeriodeTable },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
</style>