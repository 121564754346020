<template>
  <v-container>

    <div>
      <v-row>
        <v-col
            cols="12"
            sm="6"
            md="4"
        >
          <v-text-field
              v-model="itemModified.kode1"
              :rules="rulesLenght"
              label="Kode"
              dense
              hide-details
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="6"
            md="6"
        >
          <v-text-field
              v-model="itemModified.description"
              label="Nama Periode"
              dense
              :rules="rulesLenght"
              hide-details
          ></v-text-field>
        </v-col>
        <v-col
            cols="12"
            sm="2"
            md="2"
        >
          <v-switch
              v-model="itemModified.statusActive"
              :label="itemModified.statusActive?'Aktif':'Non-Aktif'"
              x-small
              dense
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            sm="5"
            md="5"
        >
          <v-autocomplete
              v-model="itemModified.fdivisionBean"
              :items="itemsFDivision"
              :rules="rulesNotEmtpy"
              item-value="id"
              item-text="description"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              label="Divisi/Unit"
              :hint="`Division = Unit : ${lookupFDivision(itemModified.fdivisionBean)}`"
              persistent-hint
              small-chips
          ></v-autocomplete>
        </v-col>
      </v-row>


      <v-row class="blue lighten-5 mt-4">
        <v-col cols="12" sm="4" md="4">

          <v-menu
              v-model="menuDate1"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs } ">
              <v-text-field
                  :value="computedDateFormattedDatefns(itemModified.daftarOpenFrom)"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  label="Pendaftaran Mulai"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="itemModified.daftarOpenFrom = null"
                  dense
                  hide-details
              ></v-text-field>
            </template>

            <v-date-picker
                v-model="itemModified.daftarOpenFrom"
                @change="menuDate1 = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col cols="12" sm="4" md="4">

          <v-menu
              v-model="menuDate2"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs } ">
              <v-text-field
                  :value="computedDateFormattedDatefns(itemModified.daftarCloseTo)"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  label="Sampai"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="itemModified.daftarCloseTo = null"
                  dense
                  hide-details
              ></v-text-field>
            </template>

            <v-date-picker
                v-model="itemModified.daftarCloseTo"
                @change="menuDate2 = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
      </v-row>

      <v-row class="green lighten-4 mt-4">
        <v-col cols="12" sm="4" md="4">

          <v-menu
              v-model="menuDate3"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs } ">
              <v-text-field
                  :value="computedDateFormattedDatefns(itemModified.periodeFrom)"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  label="Pelaksanaan Mulai"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="itemModified.periodeFrom = null"
                  dense
                  hide-details
              ></v-text-field>
            </template>

            <v-date-picker
                v-model="itemModified.periodeFrom"
                @change="menuDate3 = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
        <v-col cols="12" sm="4" md="4">

          <v-menu
              v-model="menuDate4"
              :close-on-content-click="false"
              max-width="290"
          >
            <template v-slot:activator="{ on, attrs } ">
              <v-text-field
                  :value="computedDateFormattedDatefns(itemModified.periodeTo)"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  label="Sampai"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="itemModified.periodeTo = null"
                  dense
                  hide-details
              ></v-text-field>
            </template>

            <v-date-picker
                v-model="itemModified.periodeTo"
                @change="menuDate4 = false"
            ></v-date-picker>
          </v-menu>

        </v-col>
      </v-row>


    </div>


    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </v-container>

</template>

<script>
import {format, parseISO} from "date-fns";
import FPeriode from "../../../models/f-periode";


export default {
  components:{
  },
  props:{
    itemModified: new FPeriode(),

    itemsFDivision: [],
    itemsFPeriode:[]
  },
  data() {
    return{
      snackBarMesage: '',
      snackbar: false,

      date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      menuDate2: false,
      menuDate3: false,
      menuDate4: false,


      selectedItemIndex: -1,
      itemDefault: '',
      // itemModified: new FPeriode(0, '', ''),
      selectFDivision: {id: 0, kode1: '', description: '' },
      // itemsFDivision: [
      //   new FDivision()
      // ],

      valid: false,
      rulesLenght: [
        v => !!v || ' Tidak Boleh Kosong',
        v => v.length <= 255 || 'Maksimal 255 Karakter',
        v => v.length > 1 || 'Harus lebih besar dari 1 karakter'
      ],
      rulesNotEmtpy: [
        v => !!v || 'Tidak boleh kosong'
      ],
      transparent: 'rgba(255, 255, 255, 0)',
    }
  },
  methods:{
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return `${str[0].description} (${str[0].kode1})`
      }else {
        return '-'
      }
    },



  }

}

</script>


<style scoped>
</style>
